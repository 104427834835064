import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { denormalize } from "normalizr";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { TVehicle } from "types";
import { vehicleSchema } from "schemas";
import { CatalogStore } from "stores";

import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";

import { cartItem } from "fixtures";

import Detail from "pages/Detail/components/Finance/Detail";

interface ICartItem {
  id: number | string;
  finance: any;
}

const CartItem = ({ id, finance }: ICartItem = cartItem) => {
  const { state: Catalog } = React.useContext(CatalogStore);

  const vehicle = denormalize(
    Catalog.entities.vehicle[id],
    vehicleSchema,
    Catalog.entities
  );

  const {
    modell,
    version,
    pictures,
    fzart,
    verbrauch_kombiniert,
    co2_emissionen,
  }: TVehicle = vehicle;

  return (
    <Card variant="outlined" sx={{ p: 2 }}>
      <Grid container>
        <Grid item xs={12} sm={3} sx={{ textAlign: "center" }}>
          <CardMedia
            component="img"
            image={pictures[0].src}
            sx={{ aspectRatio: "4/3", maxHeight: 200 }}
          />
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            sx={{ mt: 1 }}
            to={`/catalog/${modell.id}/${vehicle.id}`}
            component={Link}
            color="secondary"
          >
            <FormattedMessage id="common.edit" defaultMessage="Bearbeiten" />
          </Button>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Box sx={{ mx: 2 }}>
            <Grid container>
              <Grid item xs={12} md={7}>
                <Typography variant="h5">
                  {modell.marke.name}&nbsp;{modell.name}
                </Typography>
                <Typography variant="h6">
                  <span>{version}</span>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id={"fzart." + fzart.id + ".label"} />
                </Typography>
                {verbrauch_kombiniert ? (
                  <Typography variant="body2">
                    <FormattedMessage id="term.consumption.combined" />
                    :&nbsp;
                    <FormattedNumber
                      maximumFractionDigits={1}
                      minimumFractionDigits={1}
                      value={verbrauch_kombiniert}
                    />
                    &nbsp; l / 100km`
                  </Typography>
                ) : null}
                {co2_emissionen ? (
                  <Typography variant="body2">
                    <FormattedMessage id="term.co2Emission.combined" />
                    :&nbsp;
                    <FormattedNumber
                      maximumFractionDigits={0}
                      minimumFractionDigits={0}
                      value={co2_emissionen}
                    />
                    &nbsp;g/km
                  </Typography>
                ) : null}
              </Grid>
              {finance && (
                <Grid item xs={12} md={5} sx={{ typography: "body1" }}>
                  <Detail {...{ vehicle, financeRate: finance }} short />
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CartItem;
