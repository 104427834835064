import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { extent } from "d3-array";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

import { CatalogStore, FilterStore } from "stores";

import Filter from "../../Filter";

import TuerenReducer from "./TuerenReducer";
import TuerenIndicator from "./TuerenIndicator";

const TuerenFilter: React.FC = (): JSX.Element | null => {
  const [maximum, setMaximum] = useState();
  const [minimum, setMinimum] = useState();
  const [range, setRange]: [any, any] = useState();

  const {
    state: {
      entities: { vehicle = null },
    },
  } = React.useContext(CatalogStore);

  const {
    state: {
      filter: { tueren: value = null },
    },
    dispatch,
  } = React.useContext(FilterStore);

  React.useEffect(
    () => {
      dispatch({
        type: "ADD_REDUCER",
        payload: { key: "tueren", value: TuerenReducer },
      });
      dispatch({
        type: "ADD_INDICATOR",
        payload: { key: "tueren", value: TuerenIndicator },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(
    () => {
      if (!vehicle) return;
      const range: [any, any] = extent(
        Object.values(vehicle)
          .map((e: any) => e.tueren)
          .filter((e: any) => !!e)
      );
      setRange(range);

      dispatch({
        type: "INIT_FILTER",
        payload: { tueren: null },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vehicle]
  );

  React.useEffect(
    () => {
      if (!(vehicle && range)) return;
      if (!value) {
        setMinimum(range[0]);
        setMaximum(range[1]);
      } else {
        setMinimum(value[0]);
        setMaximum(value[1]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, range]
  );

  const handleChange = (element: any, newValue: any) => {
    setMinimum(newValue[0]);
    setMaximum(newValue[1]);
  };

  const submit = (element: any, value: any) => {
    const newValue =
      JSON.stringify(range) === JSON.stringify(value) ? null : value;
    dispatch({
      type: "UPDATE_FILTER",
      payload: { tueren: newValue },
    });
  };

  const getTitle = () => {
    let id = "common.tueren.span";
    let values = null;
    if (!value) {
      values = { from: range[0], to: range[1] };
    } else if (value[0] === value[1]) {
      id = "common.tueren.plural";
      values = { count: value[0] };
    } else {
      values = { from: value[0], to: value[1] };
    }

    return (
      <span>
        <FormattedMessage {...{ id, values }} />
      </span>
    );
  };

  if (!(minimum && maximum)) return null;

  const marks = [
    { value: range[0], label: range[0] },
    { value: range[1], label: range[1] },
  ];

  if (minimum > range[0]) marks.push({ value: minimum, label: minimum });
  if (maximum < range[1] && minimum !== maximum)
    marks.push({ value: maximum, label: maximum });

  const options = {
    min: range[0],
    max: range[1],
    onChange: handleChange,
    onChangeCommitted: submit,
    marks,
  };

  const Component = (
    <Box sx={{ px: 1 }}>
      <Typography gutterBottom align="right" variant="body1" color="secondary">
        {getTitle()}
      </Typography>
      <Slider value={[minimum, maximum]} {...options} />
    </Box>
  );

  return (
    <Filter
      component={Component}
      title={
        <FormattedMessage id="filter.tueren.label" defaultMessage="Türen" />
      }
    />
  );
};

export default TuerenFilter;
