import { TVehicle } from "types";

interface ISitzeReducer {
  vehicles: TVehicle[];
  filter: any;
}

const SitzeReducer = ({ vehicles, filter }: ISitzeReducer) => {
  if (!filter.sitze) return vehicles;

  return vehicles.filter(({ sitze }) => {
    return sitze && filter.sitze[0] <= sitze && filter.sitze[1] >= sitze;
  });
};

export default SitzeReducer;
