import React from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";

import { Footnote } from "components";
import { vehicle as defaultVehicle } from "fixtures";

const defaultModell = {
  ...defaultVehicle,
  picture:
    "https://img01.carliner.de/WD/hdata/h130/default_boerse/ww_10016_1.jpg",
  anzahl: 3,
  vorrat: 3,
  co2_effizienzklasse: ["B", "B"],
  rate: 1047,
  co2_emissionen: [130, 136],
  verbrauch_kombiniert: [5.7, 6],
  stromverbrauch_kombiniert: [null, null],
};

interface IModell {
  data: any;
  onClick: any;
  paperProps: any;
}

const ModellCard: React.FC<IModell> = ({
  data = defaultModell,
  onClick = () => null,
  paperProps = {},
}): JSX.Element => {
  const {
    picture,
    modell,
    vorrat,
    preis,
    rate,
    verbrauch_kombiniert,
    stromverbrauch_kombiniert,
    co2_emissionen,
    co2_effizienzklasse,
  } = data;
  return (
    <Card variant="outlined" square {...paperProps} onClick={() => onClick()}>
      <CardMedia component="img" image={picture} sx={{ aspectRatio: "4/3" }} />
      <CardContent sx={{ backgroundColor: "grey.100" }}>
        <Typography variant="h4">{modell.marke.name}</Typography>
        <Typography gutterBottom variant="h6">
          {modell.name}
        </Typography>
        <Typography variant="body2">
          <FormattedMessage
            id="common.offersPlural"
            values={{ offers: vorrat }}
          />
        </Typography>

        {rate && (
          <Box display="flex" alignItems="flex-end">
            <Box flexGrow={1}>
              <Typography variant="caption">
                <FormattedMessage
                  id="common.monthly_rate_from"
                  values={{ short: true }}
                />
              </Typography>
            </Box>
            <Typography
              color="primary"
              variant="body1"
              sx={{ fontSize: "h4.fontSize", fontWeight: "bold" }}
            >
              <FormattedNumber
                value={rate}
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency="EUR"
              />
            </Typography>
          </Box>
        )}
        <Box
          display="flex"
          alignItems="flex-end"
          typography={rate ? "body1" : "h4"}
        >
          <Box flexGrow={1}>
            <Typography variant="caption">
              <FormattedMessage
                id="common.price_from"
                defaultMessage="Kaufpreis ab"
              />
            </Typography>
          </Box>
          <Typography color="primary" variant="inherit">
            <FormattedNumber
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              value={preis}
              // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency="EUR"
            />
          </Typography>
        </Box>
      </CardContent>
      <Box
        sx={{
          backgroundColor: "grey.300",
          padding: 1,
          borderTop: "1px solid #AAAAAA",
        }}
      >
        <Typography variant="caption" color="grey.700">
          <EnergyPass
            {...{
              verbrauch_kombiniert,
              stromverbrauch_kombiniert,
              co2_emissionen,
              co2_effizienzklasse,
            }}
          />
        </Typography>
      </Box>
    </Card>
  );
};

interface IEnergyPass {
  verbrauch_kombiniert: any;
  stromverbrauch_kombiniert: any;
  co2_emissionen: any;
  co2_effizienzklasse: [string, string];
}

export const EnergyPass: React.FC<IEnergyPass> = ({
  verbrauch_kombiniert,
  stromverbrauch_kombiniert,
  co2_emissionen,
  co2_effizienzklasse,
}): JSX.Element => {
  return (
    <Stack>
      {verbrauch_kombiniert.length && verbrauch_kombiniert[0] > 0 && (
        <div>
          <FormattedMessage
            id="term.consumption.combined"
            values={{
              sub: (chunks: any) => <sub>{chunks}</sub>,
            }}
          />
          <Footnote id={1} />
          :&nbsp;
          <FormattedNumberSpan
            from={verbrauch_kombiniert[0]}
            to={verbrauch_kombiniert[1]}
            unit="l/100 km"
          />
        </div>
      )}
      {stromverbrauch_kombiniert.length && stromverbrauch_kombiniert[0] > 0 && (
        <div>
          <FormattedMessage
            id="term.consumptionElectric.combined"
            values={{
              sub: (chunks: any) => <sub>{chunks}</sub>,
            }}
            defaultMessage="Stromverbrauch kombiniert"
          />
          <Footnote id={1} />
          :&nbsp;
          <FormattedNumberSpan
            from={stromverbrauch_kombiniert[0]}
            to={stromverbrauch_kombiniert[1]}
            unit="kWh/100 km"
          />
        </div>
      )}
      {co2_emissionen.length && co2_emissionen[0] > 0 && (
        <div>
          <FormattedMessage
            id="term.co2Emission.combined"
            values={{
              sub: (chunks: any) => <sub>{chunks}</sub>,
            }}
            defaultMessage="CO2 Emissionen"
          />
          <Footnote id={1} />
          :&nbsp;
          <FormattedNumberSpan
            from={co2_emissionen[0]}
            to={co2_emissionen[1]}
            unit="g/km"
          />
        </div>
      )}
      {co2_effizienzklasse.length && co2_effizienzklasse[1] && (
        <div>
          <FormattedMessage
            id="term.energyEfficiencyClass"
            defaultMessage="CO2 Effizienzklasse"
          />
          <Footnote id={1} />
          :&nbsp;
          <FormattedTextSpan
            from={co2_effizienzklasse[0]}
            to={co2_effizienzklasse[1]}
          />
        </div>
      )}
    </Stack>
  );
};

interface IFormattedNumberSpan {
  from: number;
  to: number;
  digits?: number;
  unit?: string;
}
const FormattedNumberSpan: React.FC<IFormattedNumberSpan> = ({
  from,
  to,
  digits = 1,
  unit,
}) => {
  return (
    <span>
      <FormattedNumber
        minimumFractionDigits={digits}
        maximumFractionDigits={digits}
        value={from}
      />
      {from < to && (
        <span>
          &nbsp;
          <FormattedMessage id="common.to" defaultMessage="to" />
          &nbsp;
          <FormattedNumber
            minimumFractionDigits={digits}
            maximumFractionDigits={digits}
            value={to}
          />
        </span>
      )}
      {unit && <span>&nbsp;{unit}</span>}
    </span>
  );
};

interface IFormattedTextSpan {
  from: number | string;
  to: number | string;
  unit?: string;
}
const FormattedTextSpan: React.FC<IFormattedTextSpan> = ({
  from,
  to,
  unit,
}): JSX.Element | null => {
  const { formatMessage: t } = useIntl();
  const span = [from, to]
    .filter((v, i, a) => a.indexOf(v) === i)
    .filter((e) => !!e);

  const bis: string = t({ id: "common.to", defaultMessage: "bis" });

  let value;
  switch (span.length) {
    case 0:
      return null;

    case 1:
      value = `${span[0]}`;
      break;

    default:
      value = span.join(` ${bis} `);
  }

  return (
    <span>
      {value}
      {unit && <span>&nbsp;{unit}</span>}
    </span>
  );
};

export default ModellCard;
