import { TVehicle } from "types";

interface ITuerenReducer {
  vehicles: TVehicle[];
  filter: any;
}

const TuerenReducer = ({ vehicles, filter }: ITuerenReducer) => {
  if (!filter.tueren) return vehicles;

  return vehicles.filter(({ tueren }) => {
    return tueren && filter.tueren[0] <= tueren && filter.tueren[1] >= tueren;
  });
};

export default TuerenReducer;
