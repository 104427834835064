import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import { Controller } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Select } from "components";
import { phoneCodes, favourites } from "fixtures";

interface IIntPhone {
  name: string;
  onChange: any;
  value: string;
  required?: boolean;
  countryDisabled?: boolean;
}

interface IReactHookFormIntPhone {
  control: any;
  defaultValue?: any;
  name: string;
  error?: string;
  prefix?: string;
  required?: boolean;
  countryDisabled?: boolean;
}

const ReactHookFormIntPhone = ({
  control = () => null,
  error,
  name = "test",
  required = false,
  prefix = "",
  countryDisabled = false,
  defaultValue,
  ...rest
}: IReactHookFormIntPhone): JSX.Element => {
  const labelId = `${prefix}${name}-label`;
  return (
    <>
      <FormControl
        defaultValue={defaultValue}
        {...rest}
        error={!!error}
        sx={{ flexDirection: "row" }}
      >
        <InputLabel id={labelId}>
          <FormattedMessage id={`forms.${name}.label`} />
          {required && <span>&nbsp;*</span>}
        </InputLabel>
        <Controller
          control={control}
          name={`${prefix}${name}`}
          render={({
            field: { onChange, value, name },
            fieldState: { error },
            formState,
          }) => (
            <IntPhone
              required={required}
              name={name}
              onChange={onChange}
              value={value}
              countryDisabled={countryDisabled}
            />
          )}
        />
      </FormControl>
      {error && (
        <FormHelperText error>
          <FormattedMessage id={`forms.${name}.help.${error}`} />
        </FormHelperText>
      )}
    </>
  );
};

const IntPhone: React.FC<IIntPhone> = ({
  name = "country",
  countryDisabled = false,
  value = "+49",
  onChange = () => null,
  required,
}): JSX.Element => {
  const options = Object.keys(phoneCodes);
  const [country, prefix, number] = stripCountry(value);

  const onChangeCountry = (event: any) => {
    const newValue: string = event.target.value;
    onChange("+" + phoneCodes[newValue] + number);
  };
  const onChangeNumber = (event: any) => {
    const newValue: string = event.target.value;
    if (!isNaN(parseInt(newValue)) && !newValue.startsWith("0"))
      onChange("+" + prefix + newValue);
    if (newValue.length === 0) onChange("");
  };

  return (
    <>
      <Select
        labelId="country"
        {...{
          featured: favourites,
          name,
          labelPrefix: "country",
          required,
          options,
          disabled: countryDisabled,
          value: country,
          onChange: onChangeCountry,
        }}
      />
      <TextField
        value={number}
        required={required}
        onChange={onChangeNumber}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">+{prefix}</InputAdornment>
          ),
        }}
      />
    </>
  );
};

const stripCountry = (number: string): [string, string, string] => {
  for (const [key, value] of Object.entries(phoneCodes)) {
    if (number.startsWith("+" + value))
      return [key, value, number.substring(value.length + 1)];
  }
  return ["de", "49", number];
};

export default ReactHookFormIntPhone;
