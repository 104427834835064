import React from "react";
import { FormattedMessage, FormattedDate, FormattedNumber } from "react-intl";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";

import { Tupel } from "components";

import {
  TName,
  TOrigin,
  TAddress,
  TContact,
  TLivingConditions,
  TFinancialBalance,
  TEmployment,
  TIdCard,
  TBankAccount,
} from "types";

export const NameSummary = ({
  gender,
  firstName,
  lastName,
  birthName,
}: TName) => (
  <>
    <Tupel
      labelId="forms.gender.label"
      textId={`forms.gender.options.${gender}`}
    />
    <Tupel labelId="forms.firstName.label" text={firstName} />
    <Tupel labelId="forms.lastName.label" text={lastName} />
    {birthName && <Tupel labelId="forms.birthName.label" text={birthName} />}
  </>
);

export const ContactSummary = ({ email, phone }: TContact) => (
  <>
    <Tupel labelId="forms.email.label" text={email} />
    <Tupel labelId="forms.phone.label" text={phone} />
  </>
);

export const AddressSummary = ({
  street,
  houseNumber,
  additionalAddress,
  zipCode,
  city,
  country,
}: TAddress) => {
  return (
    <>
      <Tupel labelId="forms.street.label" text={street} />
      <Tupel labelId="forms.houseNumber.label" text={houseNumber} />
      {additionalAddress && (
        <Tupel
          labelId="forms.additionalAddress.label"
          text={additionalAddress}
        />
      )}
      <Tupel labelId="forms.zipCode.label" text={zipCode} />
      <Tupel labelId="forms.city.label" text={city} />
      <Tupel
        labelId="forms.country.label"
        textId={`forms.country.${country}.label`}
      />
    </>
  );
};

export const OriginSummary = ({
  dateOfBirth,
  placeOfBirth,
  nationality,
}: TOrigin) => (
  <>
    <Tupel labelId="forms.dateOfBirth.label" date={dateOfBirth} />
    <Tupel labelId="forms.placeOfBirth.label" text={placeOfBirth} />
    <Tupel labelId="forms.nationality.label" text={nationality} />
  </>
);

export const LivingConditionsSummary = ({
  maritalStatus,
  propertyType,
  dependentChildren,
}: TLivingConditions) => (
  <>
    <Tupel
      labelId="forms.maritalStatus.label"
      textId={`forms.maritalStatus.options.${maritalStatus}`}
    />
    <Tupel
      labelId="forms.propertyType.label"
      textId={`forms.propertyType.options.${propertyType}`}
    />
    <Tupel labelId="forms.dependentChildren.label" number={dependentChildren} />
  </>
);

export const FinancialBalanceSummary = ({
  netIncome,
  additionalIncome,
  additionalIncomeTypes,
  loading,
  maintenanceObligations,
  otherCreditObligations,
}: TFinancialBalance) => (
  <>
    <Tupel labelId="forms.netIncomelabel" euro={netIncome} />
    <Tupel labelId="forms.additionalIncomelabel" euro={additionalIncome} />

    <MultiSelectItem
      labelId="forms.additionalIncome.headline"
      name="additionalIncomeTypes"
      values={additionalIncomeTypes}
      xs={12}
      sm={12}
      md={12}
      lg={12}
    />
    <Tupel labelId="forms.loadinglabel" euro={loading} />
    <Tupel
      labelId="forms.maintenanceObligationslabel"
      euro={maintenanceObligations}
    />
    <Tupel
      labelId="forms.otherCreditObligationslabel"
      euro={otherCreditObligations}
    />
  </>
);

export const EmploymentSummary = ({
  typeOfEmployment,
  occupation,
  employedSince,
  companyName,
  legalForm,
  annualTurnover,
  address,
}: TEmployment) => (
  <>
    <Tupel
      labelId="forms.typeOfEmployment.label"
      textId={`forms.typeOfEmployment.${typeOfEmployment}.label`}
    />
    <Tupel labelId="forms.occupation.label" text={occupation} />
    <Tupel labelId="forms.employedSince.label" date={employedSince} />
    <Tupel labelId="forms.companyName.label" text={companyName} />
    <Tupel
      labelId="forms.legalForm.label"
      textId={`forms.legalForm.${legalForm}.label`}
    />
    <Tupel labelId="forms.annualTurnover.label" euro={annualTurnover} />
    <Box mt={2}>
      <AddressSummary {...address} />
    </Box>
  </>
);

export const IdCardSummary = ({
  idCardType,
  idCardNumber,
  issuingAuthority,
}: TIdCard) => (
  <>
    <Tupel
      labelId="forms.idCardType.label"
      textId={`forms.idCardType.options.${idCardType}`}
    />
    <Tupel labelId="forms.idCardNumber.label" text={idCardNumber} />
    <Tupel labelId="forms.issuingAuthority.label" text={issuingAuthority} />
  </>
);

export const BankSummary = ({ iban, bic }: TBankAccount) => (
  <>
    <Tupel labelId="forms.iban.label" text={iban} />
    <Tupel labelId="forms.bic.label" text={bic} />
  </>
);

export const Item: React.FC = ({ children, ...rest }) => (
  <Grid item xs={12} sm={6} md={4} lg={3} {...rest}>
    {children}
  </Grid>
);

interface ITextItem {
  name?: any;
  value?: any;
}
export const TextItem: React.FC<ITextItem> = ({
  name = "",
  value = "",
  ...rest
}): JSX.Element => (
  <Item {...rest}>
    <Text name={name} value={value} />
  </Item>
);

interface ISelectItem {
  name: any;
  value: any;
}
export const SelectItem: React.FC<ISelectItem> = ({
  name,
  value,
  ...rest
}): JSX.Element => (
  <Item {...rest}>
    <Text
      name={name}
      value={<FormattedMessage id={`forms.${name}.options.${value}`} />}
    />
  </Item>
);

export const MultiSelectItem = ({
  name = "",
  values = [],
  labelId = "",
  ...rest
}) => (
  <Item {...rest}>
    <Text
      name={name}
      labelId={labelId}
      value={values.map((value) => (
        <span>
          <FormattedMessage id={`forms.${name}.${value}.label`} />, &nbsp;
        </span>
      ))}
    />
  </Item>
);

interface IListItem {
  name: string;
  items: any[];
  labelId?: string;
}
export const ListItem: React.FC<IListItem> = ({
  name,
  items,
  labelId,
  ...rest
}) => (
  <Item {...rest}>
    <Text
      name={name}
      labelId={labelId}
      value={items
        .map((item) => <FormattedMessage id={`forms.${name}.${item}.label`} />)
        .join(", ")}
    />
  </Item>
);

interface INumberItem {
  name: string;
  value: number;
}
export const NumberItem: React.FC<INumberItem> = ({ name, value, ...rest }) => (
  <Item {...rest}>
    <Text
      name={name}
      value={
        <FormattedNumber
          value={value}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      }
    />
  </Item>
);

interface ICurrencyItem {
  name: string;
  value: any;
}
export const CurrencyItem: React.FC<ICurrencyItem> = ({
  name,
  value,
  ...rest
}) => (
  <Item {...rest}>
    <Text
      name={name}
      value={
        <FormattedNumber
          value={value}
          // eslint-disable-next-line react/style-prop-object
          style="currency"
          currency="EUR"
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      }
    />
  </Item>
);

interface IDateItem {
  name: string;
  value: any;
  labelId?: string;
}
export const DateItem: React.FC<IDateItem> = ({ name, value, ...rest }) => (
  <Item {...rest}>
    <Text
      name={name}
      value={
        <FormattedDate
          value={value}
          day="2-digit"
          month="2-digit"
          year="numeric"
        />
      }
    />
  </Item>
);

interface IText {
  name: string;
  value: any;
  labelId?: string;
}

const Text: React.FC<IText> = ({
  name = "test",
  value = "test",
  labelId = null,
}): JSX.Element => (
  <span>
    <FormattedMessage id={labelId || `forms.${name}.label`} />: {value}
  </span>
);

interface ICheckboxItem {
  name: string;
}

export const CheckboxItem: React.FC<ICheckboxItem> = ({ name, ...rest }) => (
  <Item {...rest}>
    <Checkbox name={name} />
  </Item>
);
