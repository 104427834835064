import React from "react";
import { Helmet } from "react-helmet-async";

import { TVehicle } from "types";

import { vehicle as defaultVehicle } from "fixtures";

interface IHeader {
  vehicle: TVehicle;
}

const Header: React.FC<IHeader> = ({
  vehicle = defaultVehicle,
}): JSX.Element => {
  const href = window.location.href;
  const { modell, version = "", pictures = [] } = vehicle;

  const preview = pictures && pictures.length > 0 ? pictures[0].src : null;

  return (
    <Helmet>
      <meta
        property="og:title"
        content={`${modell.marke.name} ${modell.name}`}
      />
      <title>{`${modell.marke.name} ${modell.name} - ${version}`}</title>
      <meta property="og:url" content={href} />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content="carbando" />
      <meta property="og:description" content={version} />
      {preview && <meta property="og:image" content={preview} />}
    </Helmet>
  );
};

export default Header;
