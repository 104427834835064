import React from "react";
import { useIntl } from "react-intl";

import Chip from "../../ChipIndicator";

interface ITuerenIndicator {
  filter: [number, number] | number | null;
  dispatch?: any;
}

const TuerenIndicator = ({
  filter,
  dispatch,
}: ITuerenIndicator): JSX.Element | null => {
  const { formatMessage: t } = useIntl();

  if (!filter) return null;

  const label =
    Array.isArray(filter) && filter[0] !== filter[1]
      ? t(
          {
            id: "common.tueren.span",
            defaultMessage: "{from} bis {to} Türen",
          },
          { from: filter[0], to: filter[1] }
        )
      : t(
          {
            id: "common.tueren.plural",
            defaultMessage:
              "{count, plural, =0 {keine Tür} one {eine Tür} other {# Türen}}",
          },
          { count: Array.isArray(filter) ? filter[0] : filter }
        );

  const handleDelete = (tueren: any) => {
    dispatch({
      type: "UPDATE_FILTER",
      payload: { tueren: null },
    });
  };

  return (
    <Chip id="tueren" label={label} onDelete={() => handleDelete("tueren")} />
  );
};

export default TuerenIndicator;
